import * as React from "react"
import stars from '../images/404_stars.jpg'
import logo from '../images/conex-logo-white.webp'


const NotFoundPage = () => {
    return (
        <main className="w-screen h-screen bg-black grid grid-rows-1 justify-center items-center text-center text-white">
            <title>Not found</title>
                {/* <div className="h-5/6 flex flex-col justify-center items-center">
                    <h1 className="font-bold text-9xl">404</h1>
                    <h2 className="font-semibold text-3xl">Houston, we can't find that page!</h2>
                    <div className="mt-16">
                        <a href="/" className="px-5 py-3 bg-conex-blue rounded-md font-semibold text-2xl">HOME</a>
                    </div>
                </div>
                <img
                    className="h-16 w-auto justify-self-end"
                    src={logo}
                    alt="Conex Research"
                /> */}
                <img src={stars} className='row-start-1 col-start-1 w-full h-full'/>

                <div className='row-start-1 col-start-1 w-full h-full flex flex-col sm:flex-row justify-center items-center'>
                    <div className='flex flex-col text-center'>
                        <h1 className="font-bold text-7xl mt-10 sm:mt-0 sm:text-9xl">404</h1>
                        <h2 className="font-semibold text-2xl sm:text-3xl px-5">Houston, we can't find that page!</h2>
                        <div className="mt-16 z-10">
                            <a href="/" className="px-5 py-3 bg-conex-blue rounded-md font-semibold text-2xl">HOME</a>
                        </div>            
                    </div>
                </div>

                <img
                    className="row-start-1 col-start-1 h-16 self-end justify-self-center mb-10"
                    src={logo}
                    alt="Conex Research"
                />
        </main>
    )
}

export default NotFoundPage
